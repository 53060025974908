import config from "react-global-configuration";
import dev from "./dev";
import prod from "./prod";
import caterpillar from "./caterpillar";
import pgcadd from "./pgcadd";
import strco from "./strco";
import elegant from "./elegant";

if (window.location.hostname === "localhost") {
  config.set(prod, { freeze: false });
  config.set(caterpillar, { freeze: false });
  config.set(pgcadd, { freeze: false });
  config.set(strco, { freeze: false });
  config.set(elegant, { freeze: false });
  config.set(dev, { assign: true });
} else if (window.location.hostname === "login.pgcadd.com") {
  config.set(caterpillar, { freeze: false });
  config.set(dev, { freeze: false });
  config.set(pgcadd, { freeze: false });
  config.set(strco, { freeze: false });
  config.set(elegant, { freeze: false });
  config.set(prod, { assign: true });
} else if (window.location.hostname === "dev.stringconsultants.com") {
  config.set(prod, { freeze: false });
  config.set(dev, { freeze: false });
  config.set(pgcadd, { freeze: false });
  config.set(strco, { freeze: false });
  config.set(elegant, { freeze: false });
  config.set(caterpillar, { assign: true });
} else if (window.location.hostname === "pgcadd.stringconsultants.com") {
  config.set(prod, { freeze: false });
  config.set(dev, { freeze: false });
  config.set(caterpillar, { freeze: false });
  config.set(strco, { freeze: false });
  config.set(elegant, { freeze: false });
  config.set(pgcadd, { assign: true });
} else if (window.location.hostname === "elegant.stringconsultants.com") {
  config.set(prod, { freeze: false });
  config.set(dev, { freeze: false });
  config.set(caterpillar, { freeze: false });
  config.set(strco, { freeze: false });
  config.set(pgcadd, { freeze: false });
  config.set(elegant, { assign: true });
} else if (window.location.hostname === "strco.stringconsultants.com") {
  config.set(prod, { freeze: false });
  config.set(dev, { freeze: false });
  config.set(caterpillar, { freeze: false });
  config.set(pgcadd, { freeze: false });
  config.set(elegant, { freeze: false });
  config.set(strco, { assign: true });
} else {
  config.set(caterpillar, { freeze: false });
  config.set(dev, { freeze: false });
  config.set(pgcadd, { freeze: false });
  config.set(elegant, { freeze: false });
  config.set(strco, { freeze: false });
  config.set(prod, { assign: true });
}
